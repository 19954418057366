const state = {
    overlay: false
  };
  
  const getters = {
  
  };
  
  const mutations = {
    openOverlay(state) {
      state["overlay"] = true;
    },
    closeOverlay(state) {
      state["overlay"] = false;
    }
  }
  
  const actions = {
    openOverlay({
      commit
    }) {
      commit("openOverlay");
    },
    closeOverlay({
      commit
    }) {
      commit("closeOverlay");
    }
  
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }