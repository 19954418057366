import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search')
  },
  {
    path: '/sample',
    name: 'Sample',
    component: () => import('../views/Sample')
  },
  {
    path: '/sampleChildrenPage',
    name: 'SampleChildrenPage',
    component: () => import('../views/sampleChildrenPage')
  },
  {
    path: '/gene',
    name: 'Gene',
    component: () => import('../views/Gene')
  },
  {
    path: '/geneChildrenPage',
    name: 'GeneChildrenPage',
    component: () => import('../views/geneChildrenPage')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help')
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import('../views/Download')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
