import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'; //持久化数据状态，防止刷新vuex数据丢失
import snackbar from "../store/modules/snackbar"
import overlay from "../store/modules/overlay"
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    snackbar,
    overlay
  },
  plugins: [createPersistedState({
    key: "vuex",
    reducer(val) {
      //只保存页面数据
    }
  })],
})
