import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'
import axios from "axios";

Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.prototype.$axios = axios
Vue.prototype.projectInformation = {
  groupId: '61de80fa3f6359003ec98d55',//甲基化赵老师团队id
  filterOption:{//查询接口参数模板
    "filterOption": {
      "filters": {
        "workflow": '',
      },
      "group": '61de80fa3f6359003ec98d55',
      "limit": 500
    }
  }
}


new Vue({
  axios,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
