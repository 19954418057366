const state = {
  snackbars:[]
};

const getters = {

};

const mutations = {
  openSnackbar(state, params) {
    let {
      snackbar_message,
      color,
      timeout
    } = params;
    state.snackbars.push({
      snackbar: true,
      snackbar_message: snackbar_message,
      snackbar_color: color,
      bottom: false,
      top: true,
      timeout: -1,
      id: Date.now()
    })
  },
  closeSnackbar(state, index) {
    state.snackbars.splice(index, 1)
  },
  openSuccessSnackbar(state,message){
    state.snackbars.push({
      snackbar: true,
      snackbar_message: message,
      snackbar_color: "success",
      bottom: false,
      top: true,
      timeout: 5000,
      id: Date.now()
    })
  },
  openFailureSnackbar(state,message){
    state.snackbars.push({
      snackbar: true,
      snackbar_message: message,
      snackbar_color: "error",
      bottom: false,
      top: true,
      timeout: -1,//不自动关闭
      id: Date.now()
    })
  },
  openInfoSnackbar(state,message){
    state.snackbars.push({
      snackbar: true,
      snackbar_message: message,
      snackbar_color: "warning",
      bottom: false,
      top: true,
      timeout: 5000,
      id: Date.now()
    })
  },
  cleanSnackbar(state) {
    state.snackbars = state.snackbars.filter(item=>item.snackbar)
  }
}

const actions = {
  openSnackbar({
    commit
  }, params) {
    commit("openSnackbar", params);
  },
  closeSnackbar({commit}, index) {
    commit("closeSnackbar", index);
  },
  openSuccessSnackbar({commit}, message){
    commit("openSuccessSnackbar", message)
  },
  openFailureSnackbar({commit}, message){
    commit("openFailureSnackbar", message)
  },
  openInfoSnackbar({commit}, message){
    commit("openInfoSnackbar", message)
  },
  cleanSnackbar({commit}){
    commit("cleanSnackbar")
  }

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
