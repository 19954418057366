<template>
  <v-app>
    <v-snackbar
        v-if="snackbars.length"
        v-for="(snackItem, index) in snackbars"
        v-model="snackItem.snackbar"
        top
        :color="snackItem.snackbar_color"
        :timeout="snackItem.timeout"
        :style="`top:${(index+1)*8+index*52}px !important;padding-top:0px;` "
        @input="cleanSnackbar"
        :key="snackItem.id"
    >
      <v-tooltip
          v-if="snackItem.snackbar_message.length>50"
          bottom
          :key="`${snackItem.id}_tooltip`">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">{{snackItem.snackbar_message.slice(0, 50)+"..."}}</div>
        </template>
        <span>{{snackItem.snackbar_message}}</span>
      </v-tooltip>
      <template v-else>{{snackItem.snackbar_message}}</template>
      <template v-slot:action="{ attrs }">
        <v-btn color="white"
               text
               v-bind="attrs"
               @click="closeSnackbar(index)">关闭</v-btn>
      </template>
    </v-snackbar>
    <v-overlay :value="overlay" z-index="1000" opacity="0.5">
      <v-progress-circular indeterminate :color="'white'"></v-progress-circular>
    </v-overlay>
    <v-app-bar
      app
      color="#d9e0e7"
      style="height:80px;display:flex;align-items: center;padding-left:30px"
    >
      <img :src="logo" alt="" style="height:45px;cursor: pointer;" @click="$router.push('/')">
      <h2 style="margin-left: 10px;opacity:0.7;cursor: pointer;margin-right: 110px" @click="$router.push('/')">{{title}}</h2>
      <template v-for="(item,index) in appBar">
        <v-btn v-if="item.name !== '检索' && item.name !== '基因组浏览器'" color="#d9e0e7" elevation="0" style="font-size:18px;font-weight:800;opacity:0.7;margin-left:30px" :to="item.url">
          {{item.name}}
        </v-btn>
        <v-btn v-if="item.name === '基因组浏览器' " color="#d9e0e7" elevation="0" style="font-size:18px;font-weight:800;opacity:0.7;margin-left:30px" @click="JWBrowser(item.url)">
          {{item.name}}
        </v-btn>
        <v-menu offset-y open-on-hover class="text-center"  v-if="item.name === '检索' ">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="#d9e0e7"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                style="font-size:18px;font-weight:800;opacity:0.7;margin-left:30px"
            >
              {{item.name}}
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                v-for="(children, idx) in item.child"
                :key="idx"
            >
              <v-list-item-title @click="$router.push(children.url)" style="cursor: pointer">{{ children.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

    </v-app-bar>

    <v-main style="padding:80px 30px 30px 30px">
      <keep-alive>
        <router-view :project="project === '1'" :JWURL="appBar[2]['url']"/>
      </keep-alive>
    </v-main>
    <v-footer padless>
      <v-col
          class="text-center"
          cols="12"
      >
        Copyright 2022 — {{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: 'App',
  data: () => ({
    logo: require('../public/image/logos.png'),
    appBar: [
      {
        name: '首页',
        url: '/home'
      },
      {
        name: '检索',
        url: '/search',
        child:[
          {
            name: '样本',
            url: '/sample'
          },
          {
            name: '基因',
            url: '/gene',
          }
        ]
      },
      {
        name: '基因组浏览器',
        url: 'http://jbrowse.cloudna.cn/?data=data/test'
      },
      {
        name: '帮助',
        url: '/help'
      },
      {
        name: '下载',
        url: '/download'
      }
    ],
    title:'转录组及甲基化特征数据库',
    project:''

  }),
  computed:{
    ...mapState("snackbar", ["snackbars"]),
    overlay: {
      get: function() {
        return this.$store.state.overlay.overlay;
      },
      set: function() {
        this.closeOverlay();
      }
    }
  },
  mounted(){
    //监听页面大小变化，并初始化图表，可以解决页面大小调整后图表大小不适配问题。弊端：频繁刷新页面，请求接口，如果不做数据缓存，会增加后端负担。
    // window.addEventListener('resize',() => window.location.reload(), false)
    if(!sessionStorage.getItem('project')){
      sessionStorage.setItem('project','1')
      this.project = '1'
    }else{
      this.project = sessionStorage.getItem('project')
    }
    this.title = sessionStorage.getItem('project') === '1' ? '转录分子特征数据库' : '表观遗传特征数据库'
    if(this.project!==sessionStorage.getItem('project')){

    }
  },
  activated(){

  },
  watch: {
    $route() {
      if(this.$route.query && this.$route.query.project && this.$route.query.project!==sessionStorage.getItem('project')){
        let project = this.$route.query.project
        if(!['1','2'].includes(project)){
          project = '1'
        }
        sessionStorage.removeItem('project')
        sessionStorage.setItem('project',project)
        //入口更换后，要删除session中的某些数据
        sessionStorage.removeItem('geneInfo')
        // sessionStorage.removeItem('physicalFitnessData')
        // sessionStorage.removeItem('top200')
        // sessionStorage.removeItem('averageScore')
        // sessionStorage.removeItem('sampleId')
        // sessionStorage.removeItem('methylationKeys')
        // sessionStorage.removeItem('tenPhysical')
        // sessionStorage.removeItem('mainType')
        location.reload()
        this.$router.push({path:'/'})

      }
    },
  },
  methods:{
    ...mapActions("snackbar", ["openSnackbar", "closeSnackbar", "cleanSnackbar"]),
    ...mapActions("overlay", ["openOverlay", "closeOverlay"]),
    JWBrowser(url){
      window.open(url)
    }
  }
};
</script>
<style scoped>
>>>.v-btn {
  text-transform: none;
  font-weight: 900;
}
</style>
