<template>
  <v-container style="padding-top: 40px">
    <v-row style="padding-left: 4%">
      <v-col :cols="2">
        <img :src="project ? home1 : home3" alt="" width="400px">
      </v-col>
      <v-spacer></v-spacer>
      <v-col :cols="7" style="margin:20px;">
        <h2 style="text-align:center;letter-spacing: 3px">{{project ? '转录分子特征数据库' : '表观遗传特征数据库'}}</h2>
        <v-divider></v-divider>
        <div class="textContainer">
          <p style="line-height:1.5;font-size:18px;font-weight:700;margin-top:15px;letter-spacing: 3px;text-indent: 2em" class="text">{{information}}</p>
        </div>
      </v-col>
    </v-row>
    <v-card style="border: 1px solid #cccccc" elevation="0">
      <div style="height:50px;background-color:#242a30;">
        <h2 style="color:white;line-height: 50px;padding-left: 20px;">Overview</h2>
      </div>
      <v-row style="height:300px;border-bottom: 1px solid #cccccc;margin:0 !important" align="center" justify="center">
        <v-col v-for="(item,index) in overviewData" :cols="3" style="text-align:center">
          <h2>{{ item.text }}</h2>
          <div style="display:inline-block;align-items: center">
            <img :src="item.image" alt="" width="50px" style="display:inline-block;vertical-align:middle">
            <span style="display:inline-block;vertical-align:middle;font-size:20px;font-weight:800;color:#242a30">{{item.number}}</span>
          </div>
        </v-col>
      </v-row>
      <div v-if="project" style="border-top:1px solid #cccccc;text-align:center;border-bottom:1px solid #cccccc">
        <h2 style="display:inline-block;line-height: 1.5;padding:5px;background-color: #d9e0e7;margin-top:30px">转录标识物的主要类型</h2>
        <v-row style="height:300px" align="center" justify="center">
          <v-col v-for="(item,index) in mainType" :cols="2" style="text-align:center">
            <h2>{{ item.text }}</h2>
            <div style="display:inline-block;align-items: center">
              <img :src="item.image" alt="" width="50px" style="display:inline-block;vertical-align:middle">
              <span style="display:inline-block;vertical-align:middle;font-size:20px;font-weight:800;color:#242a30">{{item.number}}</span>
            </div>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col :cols="6">
          <div id="donutChart" style="width:100%;height:600px"></div>
        </v-col>
        <v-col :cols="6">
          <div id="histogram" style="width:100%;height:600px"></div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import * as echarts from 'echarts';
import {statistics,getWorkflow} from '../JS/utils'

export default {
  name: 'Home',
  data: ()=>({
    home1: require('../../public/image/home-1.png'), //home页面文字介绍部分配图
    home2: require('../../public/image/bg_image_1.png'), //home页面文字介绍部分配图
    home3: require('../../public/image/home-3.png'), //home页面文字介绍部分配图
    overviewData: [//overview部分展示的数据
      {
        text:'测序样本例数',
        image: require('../../public/image/overviewImage/1.png'),
        number: 0
      },
      {
        text:'体能考核维度',
        image: require('../../public/image/overviewImage/2.png'),
        number: 10
      },
      {
        text:'体能差异甲基化基因',
        image: require('../../public/image/overviewImage/3.png'),
        number: 349
      }
    ],
    mainType: [ //转录标识物的主要类型数据
      {
        text:'lncRNA',
        value:'lncRNA',
        image: require('../../public/image/mainTypeImage/1.png'),//image调取本地图片资源
        number: 600 //number需要请求接口根据返回数据计算得出
      },
      {
        text:'miRNA',
        value:'miRNA',
        image: require('../../public/image/mainTypeImage/2.png'),
        number: 600
      },
      {
        text:'snRNA',
        value:'snRNA',
        image: require('../../public/image/mainTypeImage/3.png'),
        number: 600
      },
      {
        text:'Protein coding',
        value:'protein_coding',
        image: require('../../public/image/mainTypeImage/4.png'),
        number: 600
      },
      {
        text:'circRNA',
        value:'circRNA',
        image: require('../../public/image/mainTypeImage/5.png'),
        number: 600
      }
    ],
    physicalFitnessData:null,//体能数据（后端返回）
    geneInfoData:null,//基因信息（后端返回）
    tenPhysical:[

    ],
    information:'',
  }),
  props:[
      'project'
  ],
  components: {

  },
  async mounted(){
    let that = this
    this.overviewData[2]['text'] = this.project ? '体能差异转录组基因' : '体能差异甲基化基因'
    this.information = this.project ? '体能转录数据库包含600例以上的测序转录组数据，为体能核心要素力量、耐力、速度、爆发力、柔韧性等提供转录组核心生物标记物，结构化展示10种体能表型与5类转录分子特征，实现了转录组表达图谱的可视化。数据库可实现数据的浏览、搜索、下载等功能。' : '表观遗传数据库包含600例以上的甲基化测序数据，展现核心体能要素力量、耐力、速度、爆发力、柔韧性等10个维度的表观核心生物标记物。该数据库具有可交互式界面，对表观数据进行实时查询、在线浏览、自定义下载及可视化分析展示等功能。'
    await this.getPhysicalFitnessData()
    await this.getGeneInfo()
    await this.getMainType()
    this.initChart()
  },
  methods:{
    //初始化环形图，数据由后端返回
    //todo: 可以在刚进入本页面时查看section数据，如果没有存储图表数据，则请求接口并存储这些数据，减少请求接口次数。弊端：如果用户中途在样本库系统修改了数据，而session中有数据，则本网站数据并不会实时刷新，需要再确认。（经确认，本应用不存在中途修改数据情况，可以session缓存。）
    initDonutChart(){
      let physicalFitnessData = this.physicalFitnessData,
          len = physicalFitnessData.length,
          nationData = [],
          ageData = [],
          allPhysicalFitnessData = []
      nationData = statistics(physicalFitnessData,item=>{return item.submission.nation})
      ageData = statistics(physicalFitnessData,item=>{return item.submission.age})
      allPhysicalFitnessData = statistics(physicalFitnessData,item=>{return item.submission.physicalFitnessRating})
      var myChart = echarts.init(document.getElementById('donutChart'))
      myChart.setOption(
          {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          data: ['优', '良', '中', '差'],
          left: '5%',
          orient: 'vertical'
        },
        color: [
          '#FDDD8D',
          '#E3CDDA',
          '#B0CAC7',
          '#FEF4C9',
          '#9DBFA2',
          '#CDD796',
          '#C4AFD2',
          '#C2ABC0',
          '#98B994',
          '#CEAABD',
          '#9189A5',
          '#FCA95E',
          '#D68E8E',
          '#8EB152',
          '#47A49B',
          '#FCAE82',
          '#B08837',
          '#A6DDC2',
          '#E0B7A9',
          '#D966A5',
          '#2B6919',
          '#539725',
          '#ACD9E6',
          '#C8DCCC',
          '#D3D8D7',
          '#E3C264',
          '#F2B6B4',
          '#E3AFD5',
          '#EEEDBC',
          '#A5B8B3',
          '#AF7222',
          '#E6B9A2',
          '#B8CCE3',
          '#F9D8AA',
          '#74ADD1',
          '#99DD8B',
          '#D3D6BF',
          '#F7B5CE',
          '#AEC7E8',
          '#A6D398',
          '#0C7169',
          '#C79D98',
          '#9C2A76',
          '#62A3CB'
        ],
        series: [
          {
            name: '体能总评',
            type: 'pie',
            radius: ['15%', '25%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: allPhysicalFitnessData
          },
          {
            name: '年龄',
            type: 'pie',
            radius: ['35%', '50%'],
            labelLine: {
              length: 30,
              show: true
            },
            label: {
              position: 'inner',
              fontSize: 14
            },
            data: ageData
          },
          {
            name: '民族',
            type: 'pie',
            radius: ['60%', '75%'],
            labelLine: {
              length: 30,
              show: true
            },
            label: {
              position: 'outside',
              fontSize: 14
            },
            data: nationData
          }
        ]
      }
      );
    },
    async histogram(){
      if(!sessionStorage.getItem('tenPhysical')){
        let res = await getWorkflow({
          "filterOption": {
            "filters": {
              "workflow": '62f5b7b57f7126003ed01d71',
              "submission.id": {"$eq":"tenPhysical"}
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 1
          }
        })
        sessionStorage.setItem('tenPhysical',res.results[0]['submission']['data'])
      }
      let tenPhysical = JSON.parse(sessionStorage.getItem('tenPhysical')),
          data1=[],
          data2=[],
          data3=[]
      for (let i = 0; i < tenPhysical.length; i++) {
        data1.push(tenPhysical[i]['text'])
        data2.push(tenPhysical[i]['transcriptomeDifferences'])
        data3.push(tenPhysical[i]['differencesMethylationFitness'])
      }
      var myCharts = echarts.init(document.getElementById('histogram'))
      myCharts.setOption(
          {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              data: ['转录差异基因', '甲基化差异基因']
            },
            xAxis: [
              {
                type: 'category',
                axisTick: { show: false },
                axisLabel:{rotate:45},
                data: data1
              }
            ],
            yAxis: [
              {
                type: 'value'
              }
            ],
            series: [
              {
                name: '转录差异基因',
                type: 'bar',
                barGap: 0,
                emphasis: {
                  focus: 'series'
                },
                data: data2
              },
              {
                name: '甲基化差异基因',
                type: 'bar',
                emphasis: {
                  focus: 'series'
                },
                data: data3
              }
            ]
          }
      )
    },
    initChart(){
      this.initDonutChart()
      this.histogram()
    },

    /**
     * 获取体能数据
     * @returns {Promise<void>}
     */
    async getPhysicalFitnessData(){
      if(!sessionStorage.getItem('physicalFitnessData')){
        //获取样本条数
        let physicalFitnessDataFilter = {
          "filterOption": {
            "filters": {
              "workflow": '62f0ac8864a77e00437abbbf',
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 500000
          }
        }
        let physicalFitnessData = await getWorkflow(physicalFitnessDataFilter)
        sessionStorage.setItem('physicalFitnessData',JSON.stringify(physicalFitnessData.results))
      }
      this.physicalFitnessData = JSON.parse(sessionStorage.getItem('physicalFitnessData'))
      this.overviewData[0].number = this.physicalFitnessData.length
    },
    /**
     * 获取基因信息表数据条数
     * @returns {Promise<void>}
     */
    async getGeneInfo(){
      if(!sessionStorage.getItem('geneInfo')){
        //获取样本条数
        let geneInfoFilter = {
          "filterOption": {
            "filters": {
              "workflow": '62f1f4a07f7126003ece1f48',
              [sessionStorage.getItem('project')==='1'?"submission.transcriptomeDifferences":"submission.differencesMethylationFitness"]: {"$ne":""}
            },
            "group": '61de80fa3f6359003ec98d55',
            "limit": 0
          }
        }
        let geneInfo = await getWorkflow(geneInfoFilter)
        sessionStorage.setItem('geneInfo',geneInfo.total)
      }
      this.overviewData[2].number = sessionStorage.getItem('geneInfo')
    },
    async getMainType(){
      if(!sessionStorage.getItem('mainType')){
        let mainType = this.mainType,
            len = mainType.length,
            arr = []
        for (let i = 0; i < len; i++) {
          let filter = {
            "filterOption": {
              "filters": {
                "workflow": '62f1f4a07f7126003ece1f48',
                "submission.geneType": {"$eq":`${mainType[i].value}`}
              },
              "group": '61de80fa3f6359003ec98d55',
              "limit": 0
            }
          }
          let res = await getWorkflow(filter)
          arr.push(res.total)
          mainType[i].number = res.total
        }
        sessionStorage.setItem('mainType',JSON.stringify(arr))
      }else{
        let num = JSON.parse(sessionStorage.getItem('mainType'))
        this.mainType.forEach((item,index)=>{item.number = num[index]})
      }
    }
  }
}
</script>
<style scoped>
>>>.textContainer{
  background-image:url('../../public/image/bg_image_2.png');
  background-size: 658px 298px;
  height:298px;
  padding-top:2%
  /*filter: blur(5px)*/
}
.text{
  /*background: hsla(0, 0%, 100%, .4)*/
}
</style>
