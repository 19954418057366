import Vue from 'vue';
import axios from "axios";

/**
 * 对数据中某个key下的值进行统计其个数
 * @param data {Array} 要统计的数据
 * @param key {callback,String,function} 要统计的key
 * @returns {*[]} 统计结果
 */
export function statistics(data,key){
    let arr = [],
        result = []
    function a(item,itemKey){
        let value = ''
        if(typeof itemKey === 'function'){
            value = itemKey(item)
        }else if(typeof itemKey === 'string'){
            if(!item[itemKey]){
                console.log('请输入正确的key值!')
                return value
            }
            value = item[itemKey]
        }
        return value
    }
    for (let i = 0; i < data.length; i++) {
        let temporaryValue = a(data[i],key)
        if(!arr.includes(temporaryValue)){
            arr.push(temporaryValue)
            let value = data.filter(item => {return a(item,key) === temporaryValue}).length
            result.push(
                {
                    name:temporaryValue,value:value
                }
            )
        }
    }
    return result
}
/**
 * 读取数据接口的函数
 * @param filter {Object} 接口的请求参数
 * @returns {Promise<any>}
 */
export async function getWorkflow(filter){
    let res = await axios.post('/biobank/public/workflowlaunchsAnonymous',filter)
    return res.data
}